import {axios} from '@/utils/axios';

const RESOURCE_NAME = '/reading-documents';

export default {
    get_headers(payload) {
        return axios.get(`${RESOURCE_NAME}/header`, {params: payload})
    },
    get_by_header(payload) {
        return axios.get(`${RESOURCE_NAME}/`, {params: {header_id: payload}})
    },
    get_by_header_legacy(payload) {
        return axios.get(`${RESOURCE_NAME}/legacy`, {params: {header_id: payload}})
    },
    update(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload)
    },
    update_legacy(payload) {
        return axios.post(`${RESOURCE_NAME}/legacy/update`, payload)
    },
    remove(payload) {
        return axios.post(`${RESOURCE_NAME}/remove`, { header_id: payload })
    },
    remove_legacy(payload) {
        return axios.post(`${RESOURCE_NAME}/legacy/remove`, { header_id: payload })
    },
    create(payload) {
        return axios.post(`${RESOURCE_NAME}/create`, payload)
    },
    create_legacy(payload) {
        return axios.post(`${RESOURCE_NAME}/legacy/create`, payload)
    },
    create_row(payload) {
        return axios.post(`${RESOURCE_NAME}/create/row`, payload)
    },
    create_row_legacy(payload) {
        return axios.post(`${RESOURCE_NAME}/legacy/create/row`, payload)
    },
    update_row(payload) {
        return axios.post(`${RESOURCE_NAME}/legacy/update/row`, payload)
    },
    update_row_legacy(payload) {
        return axios.post(`${RESOURCE_NAME}/legacy/update/row`, payload)
    },
    fill_document(payload) {
        return axios.post(`${RESOURCE_NAME}/document-fill`, payload)
    },
    fill_document_legacy(payload) {
        return axios.post(`${RESOURCE_NAME}/legacy/document-fill`, payload)
    }
}
